import ButtonWrapper from "./LinkButton.style";

const LinkButton = ({ children, ...props }) => {
  return (
      <a href={props.href} target={props.target}>
        <ButtonWrapper type="submit" className="bithu-btn" {...props}>
          {children}
        </ButtonWrapper>
      </a>
  );
};

export default LinkButton;
