import checkIcon from "../../images/blog/arrow.png";
import thumb1 from "../../images/team/Headcrash.jpg";
import thumb2 from "../../images/nft/blond2.png"; 
import thumb3 from "../../images/blog/art.jpg";
import thumb4 from "../../images/blog/foil.jpg";
import thumb5 from "../../images/blog/canvas.jpg";
import thumb6 from "../../images/blog/AITC.jpg";
const data = [
  {
    progressIcon: checkIcon,
    progress: "15",
    title: "Membership",
    text: "Membership at the Headcrash Hotel, getting holders of this collection presale access to future Headcrash releases and projects.",
    thumb: thumb1,
  },
  {
    progressIcon: checkIcon,
    progress: "30",
    title: "Giclee Print",
    text: "10 holders will win a unique giclee print from a scene in the animation.",
    thumb: thumb2,
  },
  {
    progressIcon: checkIcon,
    progress: "50",
    title: "The Cure T-shirt",
    text: "10 holders will win an Art Is The Cure T-shirt of their choice.",
    thumb: thumb3,
  },
  {
    progressIcon: checkIcon,
    progress: "70",
    title: "Reflections Foil Print",
    text: "10 holders will win a Reflections Foil print worth £125 / $150 each of their choice.",
    thumb: thumb4,
  },
  {
    progressIcon: checkIcon,
    progress: "80",
    title: "Original Reflections Canvas",
    text: "1 holder will win an original Reflections canvas worth $10,000.",
    thumb: thumb5,
  },
  {
    progressIcon: checkIcon,
    progress: "100",
    title: "Art Is The Cure",
    text: "Rich will be donating his profits from this collection to Art Is The Cure, a registered non profit community interest company to continue the work of inspiring people about creative therapy through talks, school visits, workshops, events, media and more.",
    thumb: thumb6,
  },
];

export default data;
