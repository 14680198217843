import aboutThumb1 from "../../images/team/1.jpg";
import aboutThumb2 from "../../images/team/2.jpg";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Following the incredible success of the Reflections NFT, Rich Simmons’ has joined forces with Headcrash Hotel to create their genesis collab. ‘Reflections at the Headcrash Hotel’ is a collection of 333 immersive cinematic loops that invite us to quite literally dive into the artwork—exploring how our art experience can and should evolve as we transition from Web2 to Web3.",
  aboutDescription2:
    "Rich Simmons is a Contemporary Urban Pop Artist who has exhibited all over the world. Simmons’s work explores the intersections of visual culture, spanning pop art, comic books, the Renaissance, contemporary fashion, sexuality, and beyond. 2022 saw the launch of Simmons NFT debut, with Reflections selling out the 3,333 mints in under an hour and going on to generate $1.8 million in sales on Opensea in the first month. The collection debuted in the top 10 Art NFTs on Opensea and earned finalist spots in the NFT NYC Awards as Best NFT Artist and Best Emerging NFT Artist.",
  aboutDescription3:
    "Headcrash Hotel is an ever-expanding Web3 space for visceral, high-concept immersive art. Built in Unreal Engine 5, with two award-winning filmmakers at the helm, Headcrash Hotel collaborates directly with other artists, offering their fans and collectors the chance to step inside their visions and create new rich, atmospheric worlds. ‘Reflections at the Headcrash Hotel’ is their first collab, so stay tuned for more NFT drops, hangouts, and immersive VR experiences. Enjoy your stay!",
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Rich Simmons",
      description: "A globally recognized artist and celebrated designer."
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Headcrash Hotel",
      description: "A globally recognized design studio and VFX visionaries"
    },
  ],
};

export default data;
