const data = [
  {
    title: "Where can I mint?",
    text: "The NFTs will ONLY be available to mint through this official website.",
  },
  {
    title: "How many NFTs will there be?",
    text: "There will ONLY be 333 NFTs available to mint in this collection.",
  },
  {
    title: "Which blockchain will they be launched on?",
    text: "They will be on Ethereum with a gas-efficient ERC721a smart contract.",
  },
  {
    title: "Are there any giveaways?",
    text: "Yes, we are doing regular giveaways to our holders, so stay tuned and HODL.",
  },
  {
    title: "How much will it cost to mint 1 NFT?",
    text: " The public minting price is set at 0.33 ETH. The Whitelist price is 0.2 ETH.",
  },
  {
    title: "Which secondary marketplace will they trade on?",
    text: "After minting, the entire collection will be available to trade on Opensea / Rarible / LooksRare.",
  },
  {
    title: "Are any NFTs being held back for the team, marketing, etc.?",
    text: "Yes, a small handful of NFTs will be held back for marketing and the team.",
  },
];

export default data;
