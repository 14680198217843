
import { useEffect, useState } from "react";
import { totalMintCount, currentPrice } from '../../../../utils/web3mint';
import { useModal } from "../../../../utils/ModalContext";

import CountdownTimer from "react-component-countdown-timer";

import Button from "../../../../common/button";
import Particle from "../../../../common/particle/v2";

import bannerThumb1 from "../../../../assets/images/banner/Item1.png";
//import bannerThumb2 from "../../../../assets/images/banner/Item2.png";
//import bannerThumb3 from "../../../../assets/images/banner/Item3.png";
import BannerStyleWrapper from "./Banner.style";

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [count, setCount] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);
  const [cost, setCost] = useState(0);
  const [mintBtnActive, setMintBtnActive] = useState(true);

  let i = 0;

  const getTotalMinted = async () => {
    await totalMintCount().then(() => {
      setTotalMinted(localStorage.getItem('mintCount'));
    }); 
  }

  const getPrice = async () => {
    await currentPrice().then(() => {
      setCost(localStorage.getItem('cost'));
    }); 
  }

  useEffect(() =>{
      getTotalMinted();
      getPrice();
      i++;
      if(account.length > 0) {
        setMintBtnActive(true);
      } else {
        setMintBtnActive(false);
      }
  },[account]);

  const settings = {
    count: 1032560,
    showTitle: true, 
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "",
    hourTitle: "",
    minuteTitle: "",
    secondTitle: "", 
    id: "countdownwrap",
  };

  return (
    <BannerStyleWrapper className="bithu_v3_baner_sect" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-image-area3">
              {/* particles component */}
              <Particle />
              <img
                className="banner-image banner-image1"
                src={bannerThumb1}
                alt="bithu banner thumb"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-conent3">
              <h4 className="banner-subtitle text-uppercase">
                Whitelist : <span className="red-color">Live</span>
              </h4>
              <h1 className="banner-title text-uppercase">Mint is live now</h1>
              <div className="bithu_v3_timer">
                <h5 className="text-uppercase">Public Mint starts in</h5>
                <div className="timer timer_1">
                  <CountdownTimer {...settings} />
                </div>
              </div>
              <h2 style={{color: "white", fontSize: "1.5rem"}}>Total Minted: {totalMinted}</h2>
              <div className="banner-bottom-text text-uppercase" style={{marginTop: "0 !important", marginBottom: "2rem"}}>
                Mint Price: {cost}
              </div>
              <div className="banner-count-inner d-flex align-items-center">
                <div className="banner-btn-area">
                  <span
                    className="input-number-decrement"
                    onClick={() => (count > 1 ? setCount(count - 1) : count)}
                  >
                    –
                  </span>
                  <input
                    className="input-number"
                    type="text"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                  />
                  <span
                    className="input-number-increment"
                    onClick={() => setCount(count + 1)}
                  >
                    +
                  </span>
                </div>
                <div className="bithu_v3_baner_buttons">
                { mintBtnActive ? 
                  (account ? 
                  <Button lg variant="mint" onClick={() => mintModalHandle()}>
                    {" "}
                    Mint now
                  </Button> :
                  <Button lg variant="mint" onClick={() => connectWalletModalHanlde()}>
                    {" "}
                    Mint now
                  </Button>)
                  :
                  (<Button lg variant="mint" disabled={true}>
                    {" "}
                    Connect wallet
                  </Button>)
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;