import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/1.jpg";
import avatarThumb2 from "../../images/team/hcteam1.jpg";
import avatarThumb3 from "../../images/team/hcteam2.jpg";
import avatarThumb4 from "../../images/team/BlockGeni3.png";

const data = [
  {
    avatar: avatarThumb1,
    name: "Rich Simmons",
    designation: "Artist",
    description: "Acclaimed contemporary urban pop artist, internationally exhibited artist and NYC NFT Best Artist Finalist. Creator of Reflections NFT and Art Is The Cure CIC.",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/richsimmonsart/ ",
      },
      {
        icon: <FaTwitter />,
        url: "http://www.twitter.com/richsimmonsart",
      },
      {
        icon: <FaInstagram />,
        url: "https://instagram.com/richsimmonsart",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "Only Child No Pets",
    designation: "Co-Artist",
    description: "BAFTA + Webby-nominated only child denied of pets. Directs ads for brands like Google, Apple, and Sony. Co-created ‘Les Elefants Terribles’ on Tezos. Now has two pets.",
    socialLinks: [
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/onlychildnopets",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/samuelabrahams/",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "xCrash_r",
    designation: "Co-Artist",
    description: "Award-winning film and VFX director on ads for Nike, Samsung, HTC & Dior. Given it all up to learn Unreal and the piano.",
    socialLinks: [
     
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/xCrash_r ",
      },
      
    ],
  },
  {
    avatar: avatarThumb4,
    name: "BlockGeni3",
    designation: "Development Team",
    description: "A UK-based development studio building fully optimized smart contracts for some of the industry's most respected artists.",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/company/block-geni3/",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/Bl0ckGeni3",
      },
      
    ],
  },
];

export default data;
