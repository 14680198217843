import { Routes, Route } from "react-router-dom";
//import HomeV1 from "../pages/homeV1";
//import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
//import Blogs from "../pages/blogs";
//import BlogDetails from "../pages/blogDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV3 />} exact />
      
    </Routes>
  );
}

export default App;
