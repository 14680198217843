import character1 from "../../images/nft/nft2.jpg";
import character2 from "../../images/nft/nft3.jpg";
import character3 from "../../images/nft/nft4.jpg";
import character4 from "../../images/nft/nft5.jpg";
import character5 from "../../images/nft/nft6.jpg";
import character6 from "../../images/nft/nft7.jpg";
import character7 from "../../images/nft/nft8.jpg";
import character8 from "../../images/nft/nft9.jpg";
import character9 from "../../images/nft/nft10.jpg";
import character10 from "../../images/nft/nft11.jpg";


const data = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character7,
  character8,
  character9,
  character10,
];

export default data;
