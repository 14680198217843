import contract from '../contracts/bithuabi.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from 'web3-utils'

window.Buffer = window.Buffer || require("buffer").Buffer; 


export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = accounts[0];
        const contractAddress = "0x2C397aacCad4B9162Ae9785f9F9B47A4B3F4e838";
        const addresses = [
            "0x4e68F453805f153575a1e71d29Fe8f161FceD918",
            "0x64801B2982Fd6fb0FAd761fBAA56d82286AE865b",
            "0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199",
            "0xb0Ff2C6F5fe8B39A0cEDA2fa337FF12aFC6d1180",
            "0x8c504FC88F5e93746995a84fd9B9e7355559fe47"
        ];
        
        if (account === undefined) {
            return "Error: please connect wallet";
        } else {
            const nftContract = new ethers.Contract(contractAddress, contract, signer);
            const leaves = addresses.map(x => keccak256(x));
            const tree = new MerkleTree(leaves, keccak256);
            const leaf = keccak256(account);
            const proof = [tree.getHexProof(leaf).join('","')];

            const getPrice = async () => {
                let cost = await nftContract.cost()
                return cost.toString();
            }
            
            const getMintStatus = async () => {
                let publicMintStatus = await nftContract.publicMintStarted();

                return (publicMintStatus) ? 'Public' : 'Private';
            }
            
            await getPrice().then(async(cost) => {
                getMintStatus().then(async (mintStatus) => {
                    let txnHash = "";

                    if(mintStatus === 'Public') {
                        txnHash = await nftContract.mint(mint_amount, {
                            gasLimit: "285000",
                            value: cost
                        });
                    } else {
                        txnHash = await nftContract.privateMint(mint_amount,proof, {
                            gasLimit: "285000",
                            value: cost
                        });
                    }

                return txnHash;
                });
            }); 
        }
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0x2C397aacCad4B9162Ae9785f9F9B47A4B3F4e838";
        
        if (signer === undefined) {
            return "Error: please connect wallet";
        } else {
            const nftContract = new ethers.Contract(contractAddress, contract, signer);
            
            await nftContract.mintCount().then((count) => {
                localStorage.setItem('mintCount', count);

                return count.toNumber();
            })
        }
    }
}

export const currentPrice = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0x2C397aacCad4B9162Ae9785f9F9B47A4B3F4e838";
        
        if (signer === undefined) {
            return "Error: please connect wallet";
        } else {
            const nftContract = new ethers.Contract(contractAddress, contract, signer);
            
            await nftContract.cost().then((cost) => {
                localStorage.setItem('cost', ethers.utils.formatEther(cost));

                return ethers.utils.formatEther(cost);
            })
        }
    }
}
