import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "#",
  },
  {
    icon: <FaTwitter />,
    url: "http://www.twitter.com/richsimmonsart",
  },
  {
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/richsimmonsart/",
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/richsimmonsart",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/richsimmonsart/",
  },
];

export default data;
